<template>
  <div id="video">
    <video v-if="videoSource" @click="playPauseVideo" @ended="displayDecision" class="videoPlayer" playsinline autoplay :muted="videoMuted" :src="videoSource" ref="videoplayer">
    </video>
    <button class="muteButton" :class="{'videoPlaying': !videoEnded}" ref="muteButton" @click="muteVideo">
      <img :src="muteIcon" alt="mute_icon" height="24" width="24">
    </button>

    <a class="linkButton" :class="{'videoPlaying': !videoEnded}" href="https://www.fhgr.ch/index.php?id=1691#c21883" target="_blank">
      <img src="../assets/icons/info.svg" alt="info_icon" height="16" width="16">
    </a>
  </div>

  <DecisionScreen :video-ended="videoEnded" :show-more-info="showMoreInfo" :decision="decision" :is-mobile="isMobile" :muted="videoMuted" @toggleinfo="toggleshowmoreinfo" />

  <PauseScreen :video-paused=videoPaused @playvideo=playPauseVideo />

</template>

<script setup>

import {ref, onMounted, computed, watch, nextTick, onBeforeMount} from "vue";
import { useRoute } from "vue-router";
import contentSource from "/src/data/content.json";
import router from "@/router";
import PauseScreen from "@/components/PauseScreen";
import DecisionScreen from "@/components/DecisionScreen";

const videoplayer = ref(null)

const showMoreInfo = ref(false);
const videoPaused = ref(true);


onMounted(()=>{
  videoplayer.value.addEventListener('canplay', () => {
    disablePlayScreenAutoplay();
  });
});

async function disablePlayScreenAutoplay () {
  await nextTick();
  if(videoplayer.value.paused === false){
    videoPaused.value = false;
  }
}

const toggleshowmoreinfo = () => {
  showMoreInfo.value = !showMoreInfo.value;
}

//TODO: Initialwert ist false
const videoEnded = ref(false);

const isMobile = ref(null);

if(window.innerWidth < 930){
  isMobile.value = true;
} else {
  isMobile.value = false;
}

const route = useRoute();

let content = contentSource;

const video = computed(() => {
  return content.videos.find(videoObj => {return videoObj.id === route.params.id});
});

const decision = computed(() => {
  return content.decisions.find(decisionObj => {return decisionObj.id === content.videos.find(videoObj => {return videoObj.id === route.params.id}).decision});
});

const videoSource = ref("");

// Videoquelle setzen. Je nach Viewport-Width andere Qualität.
if(isMobile.value){
  videoSource.value = video.value.srcMobile;
} else {
  videoSource.value = video.value.srcDesktop;
}


async function displayDecision(){
  if(video.value.isLast){
    videoPaused.value = false;
    closeFullscreen();
    router.push({name: 'End'})
  } else {
    videoPaused.value = false;
    videoEnded.value = true;
    await nextTick();
  }
}

function playPauseVideo () {
  if(videoPaused.value){
    videoplayer.value.play();
    videoPaused.value = false;
  } else {
    videoplayer.value.pause();
    videoPaused.value = true;
  }
}

addEventListener('resize', () => {
  if(window.innerWidth <= 930){
    isMobile.value = true;
  } else {
    isMobile.value = false;
  }
});

/* Video ersetzen */
const routeID = computed(() => route.params.id);
watch(routeID, () => {
  videoEnded.value = false;
  if(window.innerWidth < 930){
    videoSource.value = video.value.srcMobile;
  } else {
    videoSource.value = video.value.srcDesktop;
  }
});



function closeFullscreen() {
  if(window.innerHeight == screen.height) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  }
}

const videoMuted = ref(false);
const muteButton = ref(null);
const muteIcon = ref("/ico/unmute.svg");


//Mutestatus anhand des Localstorage richtig setzen
if(localStorage.getItem("videoMuted")){
  if(localStorage.getItem("videoMuted") === "true"){
    videoMuted.value = true;
    muteIcon.value = "/ico/mute.svg";
  } else {
    videoMuted.value = false;
    muteIcon.value = "/ico/unmute.svg";
  }
} else {
  localStorage.setItem("videoMuted", "false");
}


//Funktion, um Video stummzuschalten bei Knopfdruck
const muteVideo = () => {
  if(videoMuted.value){
    localStorage.setItem("videoMuted", "false");
    videoMuted.value = false;
    muteIcon.value = "/ico/unmute.svg";

  } else {
    localStorage.setItem("videoMuted", "true");
    videoMuted.value = true;
    muteIcon.value = "/ico/mute.svg";
  }
}


</script>
<style lang="scss">

#app:after {
  display: none;
}
</style>

<style scoped lang="scss">
@import '~/src/assets/styles/normalize.scss';
@import '~/src/assets/styles/base.scss';

#video {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
  .videoPlayer {
    width: 100%;
    height: calc(100vh - $logo_distance - 2rem - 10px);
    min-height: 100%;
    object-fit: cover;
    object-position: center center;
  }

.muteButton {
  background-color: $primary;
  color: #fff;
  z-index: 20;

  position: fixed;
  bottom: calc($logo_distance + $padding_top_bottom_logos + $padding_top_bottom_logos + 20px);
  right: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  cursor: pointer;

  border: none;
  border-radius: 50px;

  box-shadow: 0 3px 10px rgba(0,0,0,0.4);

  &:hover {
    background-color: $primary_hover;
  }

  img {
    margin-left: 0;
  }

}

@media screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {
 .muteButton {
   top: 110px;
   bottom: auto;
 }
  .videoPlaying {
    top: auto;
    bottom: 20px;
    right: 20px;
  }

}

.linkButton {
  background-color: $primary;
  color: #fff;
  z-index: 20;

  position: fixed;
  bottom: calc($logo_distance + $padding_top_bottom_logos + $padding_top_bottom_logos + 100px);
  right: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;

  cursor: pointer;

  border: none;
  border-radius: 50px;

  box-shadow: 0 3px 10px rgba(0,0,0,0.4);

  &:hover {
    background-color: $primary_hover;
  }

  img {
    width: 55%;
    height: auto;
    margin-left: 0;
  }

}

@media screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {
  .linkButton {
    top: 190px;
    bottom: auto;

    &.videoPlaying {
      top: auto;
      bottom: 90px;
      right: 20px;
    }
  }

}
</style>
