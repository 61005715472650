<template>
  <div v-if="videoPaused" @click="firePlayEvent" class="pauseScreen" >
    <img src="../assets/icons/play.svg" alt="play_icon" width="16" height="16">
  </div>
</template>

<script setup>
import { defineEmits, defineProps, computed } from 'vue';

const props = defineProps(['videoPaused']);
const emit = defineEmits(['playvideo'])

const videoPaused = computed(() => {
  return props.videoPaused;
})

const firePlayEvent = () => {
  emit('playvideo');
}

</script>

<style lang="scss" scoped>
@import '../assets/styles/base';

.pauseScreen {
  background-color: rgba($primary, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc($logo_distance + $padding_top_bottom_logos + $padding_top_bottom_logos);
  height: calc(100% - $padding_inner);
  margin: 10px 10px 10px 10px;
  box-sizing: border-box;

img {
  height: 50%;
  width: auto;
}
}
</style>