<template>
  <transition appear @enter="decisionContainerAnimation">
    <div class="decision" v-if="videoEnded">
      <div class="btnContainer">

        <transition appear @enter="questionAnimation">
          <h1>{{ decision.question }}</h1>
        </transition>

        <transition v-for="option in decision.options" :key="option.text" appear @enter="decisionBtnAnimation">
          <button
              class="button"
              ref="decisionBtn"

              :key="option.text"
              @click="$router.push(option.url)">
            {{ option.text }}
          </button>
        </transition>

      </div>
      <transition>
        <div class="infoContainer">

          <button v-if="decision.infoTitle" class="infoButton" :class="showMoreInfo ? 'infoBtnOpen' : ''" ref="infoBtn">
            <span class="decisionTitle">{{ decision.infoTitle }}</span>
            <img src="../assets/icons/plus.svg" alt="plus_icon" height="123" width="123">
          </button>


          <span v-html="decision.infos" class="additionalInfo" :class="showMoreInfo ? 'infoBoxOpen' : ''" ref="infoBox">

          </span>
        </div>
      </transition>
      <a v-if="isMobile" href="https://www.fhgr.ch/studium/masterangebot/medien-technik-und-it/digital-communication-and-creative-media-production/?gclid=EAIaIQobChMI-8fm2uzY_QIVh8zVCh0YiQCnEAAYASAAEgK2ZPD_BwE" rel="noopener noreferrer" target="_blank">
        <img class="logo-fhgr" src="../assets/fhgr_rgb_granit.png" alt="Logo FHGR Black" width="7512" height="1182">
      </a>
      <audio ref="timerMusic" loop autoplay :muted="props.muted">
        <source :src="`/sfx/waiting-${randomWaitingMusic}.mp3`" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
    </div>
  </transition>
</template>

<script setup>
import {defineProps, computed, ref, defineEmits, nextTick, watch } from 'vue';
import {gsap} from "gsap";
const timerMusic = ref(null);


const props = defineProps(['videoEnded', 'showMoreInfo', 'decision', 'isMobile', 'muted']);
const emit = defineEmits(['toggleinfo'])

const videoEnded = computed(() => {
  return props.videoEnded;
})

const showMoreInfo = computed(() => {
  return props.showMoreInfo;
})

const decision = computed(() => {
  return props.decision;
})

const isMobile = computed(() => {
  return props.isMobile;
})

const infoBtn = ref(null);
//TODO: Auskommentieren für Prod
//registerInfoBtn();


const toggleShowMoreInfo = () => {
  emit('toggleinfo');
}

const delayCount = ref(0.2);
const randomWaitingMusic = ref(0);

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

watch(timerMusic, () => {
  if(timerMusic.value) {
    timerMusic.value.volume = 0.1;
  }
})

watch(videoEnded, () => {
  if(videoEnded.value){
    randomWaitingMusic.value = randomIntFromInterval(1, 2);

    registerInfoBtn();
    delayCount.value = 0.2;
  }
});

async function registerInfoBtn(){
  await nextTick();
  if(infoBtn.value){
    infoBtn.value.addEventListener('click', toggleAdditionalInfo);
  }
}

/* Infocontainer öffnen inklusive Animationen */
const initialButtonWidth = ref(0);
function toggleAdditionalInfo(){
  if(showMoreInfo.value){
    /* Schliessen-Animation */

    if(isMobile.value){
      /* Mobile schliessen */
      infoBtn.value.removeEventListener('click', toggleAdditionalInfo)
      gsap.to(".infoButton", {
        duration: 0.3,
        delay: 0.3,
        top: "30px",
        right: "30px",
        width: "60px",
        height: "60px",
        borderRadius: "50px 50px 50px 50px",
        justifyContent: "center",
        onComplete: () => {
          infoBtn.value.addEventListener('click', toggleAdditionalInfo)
        }
      });

      gsap.fromTo(".additionalInfo", {
        opacity: 1,
        height: "100%"
      }, {
        duration: 0.3,
        opacity: 0,
        height: "0%"
      });
    } else {
      /* Desktop schliessen */
      infoBtn.value.removeEventListener('click', toggleAdditionalInfo)
      gsap.to(".infoButton", {
        duration: 0.3,
        delay: 0.3,
        top: "30px",
        right: "30px",
        width: initialButtonWidth.value + 1 + "px",
        borderRadius: "50px 0px 50px 50px",
        justifyContent: "center",
        onComplete: () => {
          infoBtn.value.addEventListener('click', toggleAdditionalInfo)
        }
      });

      gsap.fromTo(".additionalInfo", {
        opacity: 1,
        height: "100%",
      }, {
        duration: 0.3,
        opacity: 0,
        height: "0%"
      });
    }
  } else {
    /* Öffnen-Animation */
    if(isMobile.value){
      /* Mobile-Öffnen */
      infoBtn.value.removeEventListener('click', toggleAdditionalInfo)
      gsap.to(".infoButton", {
        duration: 0.3,
        right: 0,
        top: 0,
        width: "100%",
        borderRadius: "0px 0px 0px 0px",
        justifyContent: "space-between",
        onComplete: () => {
          infoBtn.value.addEventListener('click', toggleAdditionalInfo)
        }
      });

      gsap.fromTo(".additionalInfo", {
        opacity: 0,
        height: "0%"
      }, {
        duration: 0.3,
        delay: 0.3,
        opacity: 1,
        height: "100%"
      });
    } else {
      /* Desktop öffnen */
      initialButtonWidth.value = document.querySelector(".infoButton").offsetWidth;
      infoBtn.value.removeEventListener('click', toggleAdditionalInfo)
      gsap.fromTo(".infoButton", {
        width: initialButtonWidth.value + "px",
        borderRadius: "0px",
        justifyContent: "center",

      }, {
        duration: 0.3,
        width: '100%',
        justifyContent: "space-between",
        right: 0,
        top: 0,

        onComplete: () => {
          infoBtn.value.addEventListener('click', toggleAdditionalInfo)
        }
      });

      gsap.fromTo(".additionalInfo", {
        opacity: 0,
        height: "0%",
      }, {
        duration: 0.3,
        delay: 0.3,
        opacity: 1,
        height: "100%"
      });
    }
  }
  toggleShowMoreInfo();

}

/* Anpassungen auf Resize */
addEventListener('resize', () => {
  if(window.innerWidth <= 930){
    if(infoBtn.value && infoBtn.value.style) {
      if(infoBtn.value.style.width !== "100%") {
        infoBtn.value.style.width = "60px";
        infoBtn.value.style.borderRadius = "50px 50px 50px 50px";
      }
    }
  } else {
    if(infoBtn.value && infoBtn.value.style) {
      if(infoBtn.value.style.width !== "100%") {
        infoBtn.value.style.width = "auto";
        infoBtn.value.style.borderRadius = "50px 0px 50px 50px";
      }
    }
  }
});

/* Animationen */

const decisionContainerAnimation = (el) => {
  gsap.from(el, {
    opacity: 0,
    duration: 1,
    ease: "power2"
  });
}

/* Frage */

const questionAnimation = (el) => {
  gsap.from(el, {
    y: -50,
    opacity: 0,
    duration: 0.7,
    delay: delayCount.value,
    ease: "power1"
  });
  delayCount.value = delayCount.value + 0.7;
}

/* Entscheidungsbuttons */

const decisionBtnAnimation = (el) => {
  gsap.from(el, {
    /*x: -150,*/
    y: -30,
    opacity: 0,
    duration: 1.5,
    delay: delayCount.value,
    ease: "power4"
  });
  delayCount.value = delayCount.value + 0.2;
}
</script>

<style lang="scss" scoped>
@import '../assets/styles/base';

.decision {
  background-color: rgba($primary, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: calc($logo_distance + $padding_top_bottom_logos + $padding_top_bottom_logos);
  height: calc(100% - $padding_inner);
  margin: 10px 10px 10px 10px;
  box-sizing: border-box;
}

.infoContainer {
  width: calc(50% - 100px);
  height: calc(100vh - 150px);
  padding: 50px;
}

.btnContainer {
  width: calc(50% - 80px);
  max-height: calc(100vh - 150px);
  padding-left: 60px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;

  .button {
    margin-bottom: 20px;
    z-index: 15;
    display: block;
    flex-shrink: 0;
  }
}

.infoButton {
  background-color: $primary;
  color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 30px;
  right: 30px;


  margin-right: 0;
  margin-left: auto;
  z-index: 40;

  font-size: 2rem;
  font-weight: bold;
  padding: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border: none;
  border-radius: 50px;
  border-top-right-radius: 0;
  box-shadow: 0 3px 10px rgba(0,0,0,0.4);

  cursor: pointer;

  &:hover {
     background-color: $primary_hover;
   }

  img {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-left: 20px;
    transition: rotate .4s ease;
  }
}

.infoBtnOpen {
  border-radius: 0;
  z-index: 30;
  position: relative;
  top: 0;
  left: 0;
  right: 0;

  img {
    display: inline;
    rotate: -45deg;
  }
}

.additionalInfo {
  display: block;
  opacity: 0;
  height: 0;
  position: relative;
  bottom: 50px;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 20px;
  padding-top: 80px;
  box-sizing: border-box;
  overflow: auto;
  pointer-events: none;
  overflow-y: auto;
  max-height: calc(100vh - 150px);
  z-index: 0;
}

.infoBoxOpen {
  display: block;
  pointer-events: inherit;
  z-index: 20;
}

@media screen and (max-width: $mobile_breakpoint) and (orientation: landscape), only screen and (max-width: $desktop_breakpoint) and (orientation: portrait) {

  h1  {
    margin-bottom: 15px;
  }

  .infoContainer {
    width: calc(20% - 40px);
    padding: 20px;
    max-height: 100vh;
  }

  .btnContainer {
    width: calc(80% - 40px);
    padding-left: 20px;
    padding-right: 20px;
    max-height: calc(100vh - 20px);

    .button {
      margin-bottom: 15px;
    }
  }

  .infoButton {
    position: fixed;
    top: 30px;
    right: 30px;
    display: flex;
    width: 60px;
    height: 60px;
    font-size: 1.7rem;

    align-items: center;
    justify-content: center;
    border-radius: 50px 50px 50px 50px;

    &:hover {
       border-radius: 50px;
     }

    img {
      margin-left: 0;
    }

    .decisionTitle {
      display: none;
    }
  }

  .infoBtnOpen {
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;

    .decisionTitle {
      display: block;
    }
  }

  .additionalInfo {
    position: fixed;
    left: 0;
    top: 0;

    z-index: 20;
    height: 0;
    width: 100%;
    padding: 80px 30px;
    overflow: auto;

    max-height: 100vh;
  }
}

.logo-fhgr {
  width: 30vw;
  height: auto;
  position: absolute;
  bottom: 30px;
  right: 20px;
  z-index: 5;
  filter: brightness(0) invert(1);
}

</style>
<style lang="scss">
.additionalInfo{
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

</style>
